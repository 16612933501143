import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Seo from '../components/seo'
// import DashDocsLayout from '../components/dashcmm/DashDocsLayout'

const DownloadPage = ({ pageContext }) => {

  const data = useStaticQuery(graphql`
    {
      allFile(filter: { extension: { eq: "jsonld" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `)

  // const EmptyLayout = ({ children }) => <> {children} </>

  const nodes = data.allFile.edges
  return (
    <>
    {/* <DashDocsLayout pageContext={pageContext} CanvasComponent={EmptyLayout}> */}
      <Seo title="Export download" />
      <h1>Download the Rdfs models and companions here</h1>
      <ul>
        {nodes.map((file, index) => {
          return (
            <li key={`xml-${index}`}>
              <a href={file.node.publicURL} download>
                {file.node.name}
              </a>
            </li>
          )
        })}
      </ul>
    {/* </DashDocsLayout> */}
    </>
  )
}

export default DownloadPage
